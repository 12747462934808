import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import { i18n } from "../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import FilterList from "../../components/filterList/FilterList";
import Loading from "../../components/loading/loading";
import { useFilter } from "../../utils/hooks/useSearch";
import { extractUniqueValues } from "../../utils/functions/tableFilterFunctions";
import { formatDateToLocale } from "../../utils/functions/utilFunctions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F8FAFD",
    color: "#939a9f",
  },
  [`&.${tableCellClasses.body}`]: {},
}));

const solveStatus = (val) => {
  switch (val) {
    case "AWAITING_PAYMENT":
      return "Gerar RUPE";
    case "RUPE_GENERATED":
      return "RUPE Gerado";
    case "PAYMENT_REJECTED":
      return "Pagamento Rejeitado";
    case "PAID":
      return "Pago";
    case "LIQUIDATED":
      return "Liquidado";
    case "UNDER_ANALYSIS":
      return "Em Análise";
    case "WAITING_FOR_STUDENT_INFO":
      return "Aguardar info-aluno";
    case "ENROLLED":
      return "Matriculado";
    case "APPROVED":
      return "Aprovado";
    case "REJECTED":
      return "Rejeitado";
    default:
      return "Indeterminado";
  }
};

const EnrolListPage = () => {
  const { keycloak } = useKeycloak();
  const [totalPages, setTotalPages] = useState();
  const { id } = useParams();
  const [page, pageSeter] = useState( localStorage.getItem("enrollPage") ||  1);
  const [incomingDate, setIncomingDate] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [roles, setRoles] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const enrollAnalyze = (id) => {
    navigate(`/enrolls/analyse/${id}`);
  };

  const editEnroll = (id) => {
    navigate(`/enroll/${id}/edit`);
    enrollAnalyze(id);
  };

  const [selectedCreatedDates, setSelectedCreatedDates] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const statuses = extractUniqueValues(incomingDate, "status");
  const createdDates = extractUniqueValues(
    incomingDate,
    "createdDate",
    formatDateToLocale
  );

  const filters = [
    {
      label: i18n.t("others.createdAt"),
      options: createdDates,
      selectedOptions: selectedCreatedDates,
      setSelectedOptions: setSelectedCreatedDates,
    },
    {
      label: i18n.t("others.status"),
      options: statuses,
      selectedOptions: selectedStatus,
      setSelectedOptions: setSelectedStatus,
    },
  ];

  const { setCanFilter, canFilter } = useFilter();

  const filteringProps = {
    createdDate: selectedCreatedDates,
    status: selectedStatus,
  };

  const eliminarEnroll = (id) => {
    const pathUrl = `enroll/${id}`;

    const confirmDelete = window.confirm(
      "Você realmente deseja excluir esta matrícula?"
    );

    if (confirmDelete) {
      axios
        .delete(pathUrl, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            alert("Matrícula excluída com sucesso!");
            getEnrolls();
          } else {
            console.error(
              "Falha ao excluir a matrícula. Código de status:",
              response.status
            );
          }
        })
        .catch(function (error) {
          console.error("Erro:", error);
        });
    } else {
      console.log("Exclusão cancelada pelo usuário");
    }
  };
  const setPage = (page) => {
    pageSeter(parseInt(page));
    localStorage.setItem("enrollPage", page);
  };
  const handleSearch = async (searchTerm) => {
    setSearch(searchTerm);

    if (searchTerm.trim() === "") {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    const searchGraduations = async (searchTerm) => {
      try {
        const response = await axios.get(
          `${apiUrl}/enroll?search=${encodeURIComponent(searchTerm)}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token,
            },
          }
        );

        setSearchResults(
          response.data?.data.map((depart) => ({
            ...depart,
          }))
        );

        return response.data;
      } catch (error) {
        console.error("Error searching departments:", error);
        throw error;
      }
    };

    const delaySearch = setTimeout(() => {
      if (search.trim() !== "") {
        searchGraduations(search);
      }
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [search, keycloak.token]);

  const getEnrolls = async (filters) => {
    setLoading(true);

    // Return early if page is falsy
    if (!page) {
      setLoading(false);
      return;
    }

    const params = new URLSearchParams({ page: page });

    // Convert filters object to query string
    const queryString = Object.entries(filters)
      .flatMap(([key, values]) =>
        Array.isArray(values)
          ? values.map((value) => `filter=${encodeURIComponent(value)}`)
          : `filter=${encodeURIComponent(values)}`
      )
      .join("&");

    console.log("queryString: ", queryString);

    const url =
      canFilter || search
        ? `${apiUrl}${id?"/courses/"+id+"/enrolls":"/enroll"}?${queryString}`
        : `${apiUrl}${id?"/courses/"+id+"/enrolls":"/enroll"}?` + params.toString();

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      setIncomingDate(response.data?.data);
      console.log(incomingDate);

      if (localStorage.getItem("enrollPage") && response.data.data.length > 0) {
        setPage(localStorage.getItem("enrollPage") || response.data.page);
      }
      else setPage(1)

      setTotalPages(response.data.meta.totalPages);
      console.log(totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (keycloak.token) {
      getEnrolls(filteringProps);
    }

    if (keycloak.tokenParsed && keycloak.tokenParsed.realm_access) {
      setRoles(keycloak.tokenParsed.realm_access.roles || []);
    }
  }, [keycloak.token, page, canFilter]);

  return (
  <>
    <Grid
      container
      component={Paper}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        position: "relative",
        boxShadow: "none",
        padding: { xs: 2, sm: 3, md: 4 },
        maxWidth: "100%", // Garantir que ocupe toda a largura
      }}
    >
      {loading && <Loading />}
      <Grid item sx={{ width: "100%" }}>
        {!loading && (
          <div className="container-da-tabela-responsive">
            <TableContainer
              sx={{
                overflowX: "auto", // Habilita o scroll horizontal
                border: "1px solid #E9ECF5",
                "&::-webkit-scrollbar": { display: "none" },
                width: "100%", // Garante que a tabela ocupe toda a largura
                maxWidth: "100%", // Ajusta para ocupar o máximo disponível
              }}
            >
              <Table
                sx={{
                  width: "100%", // Ajusta a largura da tabela para ser 100% do container
                  minWidth: "auto", // Remover a largura mínima fixa
                  "@media (max-width: 1024px)": {
                    minWidth: "100%", // Ajusta para tela intermediária
                  },
                  "@media (max-width: 768px)": {
                    fontSize: "12px", // Menor tamanho de fonte para telas pequenas
                  },
                }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      colSpan={6}
                      sx={{
                        height: "59px",
                        fontSize: { xs: "12px", sm: "14px" },
                        "@media (max-width: 768px)": {
                          height: "auto",
                        },
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          alignItems: "flex-start",
                          justifyContent: { sm: "space-between" },
                          gap: 2,
                          fontSize: { xs: "14px", sm: "16px" },
                        }}
                      >
                        {i18n.t("enroll.title")}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: 2,
                          }}
                        >
                          <Box sx={{ width: "100%", maxWidth: "300px" }}>
                            <AutoSearch
                              placeholder={i18n.t("others.search")}
                              searchEndpoint={handleSearch}
                              search={search}
                              setSearch={setSearch}
                              sx={{ width: "100%" }}
                            />
                          </Box>
                          <Select
                            onChange={(e) => {}}
                            displayEmpty
                            inputProps={{ "aria-label": "Page size" }}
                            sx={{
                              width: { xs: "60px", sm: "80px" },
                              height: { xs: "35px", sm: "40px" },
                              fontSize: { xs: "10px", sm: "12px" },
                            }}
                          >
                            {[5, 10, 20, 50, 100].map((size) => (
                              <MenuItem key={size} value={size}>
                                {size}
                              </MenuItem>
                            ))}
                          </Select>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: "40px",
                              border: "1px solid #C3CAD9",
                              borderRadius: "5px",
                              fontSize: { xs: "10px", sm: "12px" },
                            }}
                          >
                            <FilterList
                              cardStyle={{
                                position: "absolute",
                                zIndex: "1",
                                top: "140px",
                                right: "20px",
                              }}
                              filters={filters}
                              setCanFilter={setCanFilter}
                              canFilter={canFilter}
                            />
                            {i18n.t("others.filter")}
                          </Box>
                        </Box>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: "#F8FAFD",
                      "& th": {
                        fontSize: { xs: "12px", sm: "14px" },
                      },
                    }}
                  >
                    <StyledTableCell align="left">Nº Processo/ Nº Aluno</StyledTableCell>
                    <StyledTableCell align="left">Estudante</StyledTableCell>
                    <StyledTableCell align="left">Curso</StyledTableCell>
                    <StyledTableCell align="left">Data da Criação</StyledTableCell>
                    <StyledTableCell align="left">Estado</StyledTableCell>
                    <StyledTableCell align="center">Ações</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(search.trim() === "" ? incomingDate : searchResults).map(
                    (enroll) => (
                      <TableRow
                        key={enroll.id}
                        sx={{
                          "& td": {
                            fontSize: { xs: "12px", sm: "14px" },
                          },
                        }}
                      >
                        <TableCell>{enroll.processing}</TableCell>
                        <TableCell>{enroll.student?.name}</TableCell>
                        <TableCell>{enroll?.course?.name}</TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("pt-BR").format(
                            new Date(enroll.createdDate)
                          )}
                        </TableCell>
                        <TableCell>{solveStatus(enroll.status)}</TableCell>
                        <TableCell align="left">
                          <ActionButtons
                            id={enroll.id}
                            handleVisualize={enrollAnalyze}
                            handleEdit={editEnroll}
                            handleDelete={eliminarEnroll}
                            roles={roles}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Grid>
    </Grid>
    {!loading && (
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: { xs: 5, sm: 10 },
        }}
      >
        <CustomPagination
          setPage={setPage}
          totalPages={totalPages}
          page={page}
        />
      </Grid>
    )}
  </>
);

  
  
  
};

export default EnrolListPage;



