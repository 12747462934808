import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import { IconButton } from '@mui/material';
import { yellow } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import PropTypes from 'prop-types';
import iconVisualizar from '../../assets/iconVisualizar.svg';
import iconEDit from '../../assets/iconEDit.svg';
import iconDelet from '../../assets/iconDelet.svg';

export default function ActionButtons({ id, ...props }) {
        /**
 * Renders a set of action buttons given an id and a set of handlers.
 *
 * @param {object} props - An object containing the handlers and the id.
 * @param {function} props.handleDelete - A function to handle the delete action.
 * @param {function} props.handleEdit - A function to handle the edit action.
 * @param {function} props.handleVisualize - A function to handle the visualize action.
 * @param {string} props.id - The id of the element to edit/delete/visualize.
 * @return {JSX.Element} The JSX element of the action buttons.
 */
    const solveRole = (role) => {
        return (props.roles || []).includes(role);
    };

    const { handleDelete, handleDeleteItem, handleEdit, handleVisualize, handleCancel, disabled } = props;

    return (
        <MenuList
            sx={{
                display: disabled ? 'none' : 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                flexWrap: 'wrap',
                '@media (max-width: 1024px)': {
                    gap: '8px',
                },
                '@media (max-width: 768px)': {
                    gap: '6px',
                },
                '@media (max-width: 480px)': {
                    flexDirection: 'column',
                    gap: '5px',
                },
            }}
        >
            {handleVisualize && (
                <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => handleVisualize(id)}
                    sx={{
                        '& img': {
                            width: { xs: '16px', sm: '18px', md: '20px' },
                            height: { xs: '16px', sm: '18px', md: '20px' },
                        },
                    }}
                >
                    <img src={iconVisualizar} alt="Visualize" />
                </IconButton>
            )}

            {handleEdit && (
                <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => handleEdit(id)}
                    sx={{
                        '& img': {
                            width: { xs: '15px', sm: '17px', md: '20px' },
                            height: { xs: '15px', sm: '17px', md: '20px' },
                        },
                    }}
                >
                    <img src={iconEDit} alt="Edit" />
                </IconButton>
            )}

            {solveRole("admin") && handleDelete && (
                <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => handleDelete(id)}
                    sx={{
                        svg: {
                            fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
                            color: yellow[700],
                        },
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            )}

            {handleDeleteItem && (
                <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => handleDeleteItem(id)}
                    sx={{
                        '& img': {
                            width: { xs: '15px', sm: '17px', md: '20px' },
                            height: { xs: '15px', sm: '17px', md: '20px' },
                        },
                    }}
                >
                    <img src={iconDelet} alt="Delete" />
                </IconButton>
            )}

            {handleCancel && (
                <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => handleCancel(id)}
                    sx={{
                        svg: {
                            fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
                            color: yellow[700],
                        },
                    }}
                >
                    <CancelIcon />
                </IconButton>
            )}
        </MenuList>
    );
}

ActionButtons.propTypes = {
    id: PropTypes.string.isRequired,
    handleDelete: PropTypes.func,
    handleDeleteItem: PropTypes.func,
    handleEdit: PropTypes.func,
    handleVisualize: PropTypes.func,
    handleCancel: PropTypes.func,
    disabled: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string) // If props.roles is an array of strings
    // You can add more PropTypes for other props if needed
};
