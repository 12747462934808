import * as React from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import HandleUrl from "../../utils/HandleUrl";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import { Form, Formik } from "formik";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import Textfield from "../../components/form-components/text-field";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import { MainButton } from "../../components/main-button/MainButton";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { getItem as get } from "../../utils/functions/tableCRUDFunctions";
import {
  createItem as create,
  updateItem as update,
} from "../../utils/functions/formCRUDFunctions";
import { useKeycloak } from "@react-keycloak/web";

export default function SubjectCreatePage() {
  let isDisabled = HandleUrl();
  const { id } = useParams();
  const [INITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({
    name: "",
    description: "",
    acronym: "",
    id: "",
  });

  const isUpdate = !!id;
  const [status, setStatus] = useState({
    isLoading: true,
  });
  const { keycloak } = useKeycloak();
  let page = 1;
  const apiUrl = `${process.env.REACT_APP_API_URL}/subject`;

  const [textBox, setTextBox] = useState([
    i18n.t(id ? "messages.updateTitle" : "messages.createTitle"),
    "",
  ]);
  const navigate = useNavigate(); // Hook para navegação

  const FORM_VALIDATION = Yup.object().shape({
    acronym: Yup.string().required(i18n.t("messages.required")),
    description: Yup.string().required(i18n.t("messages.required")),
    name: Yup.string().required(i18n.t("messages.required")),
    id: Yup.string().optional(),
  });

  const getItem = get(
    `${apiUrl}/${id}`,
    keycloak,
    setINITIAL_FORM_STATE,
    undefined,
    page
  );
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const formatFormData = (values) => ({
    name: values.name,
    acronym: values.acronym,
    description: values.description,
  });

  const createItem = create(formatFormData, apiUrl, setTextBox, setStatus, keycloak, "Disciplina criada com sucesso!");
  const updateItem = update(formatFormData, apiUrl, setTextBox, setStatus, keycloak, "Disciplina atualizada com sucesso!");
  return (
    <>
 <Box sx={{ 
      backgroundColor: "#FFFFFF", 
      marginRight:"10px",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", 
      padding: "20px", 
      marginTop: "-30px",  
      width: "1089px !important",
      top: "100px",  // Ajuste para controlar a posição vertical
 
      marginLeft: "-5px", 
      height:"10px",  // Ajuste para garantir que a altura seja dinâmica
      position: "absolute", // Posicionamento absoluto para ficar por cima
      zIndex: 1000, // Garante que a Box fique acima de outros elementos
    }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
        <IconButton sx={{ 
          color: "#FBC02D", 
          marginRight: "0px", 
          display: "flex", 
          alignItems: "center", 
        }}
        onClick={() => navigate('/subjects')}

        >
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-5px " , marginTop: "-8px", }} />
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px " , marginTop: "-8px", }} />
        </IconButton>
        <Typography variant="h6" sx={{ 
          fontWeight: 400, 
          fontSize: "18px", 
          color: "#FBC02D", 
          marginRight: "10px", 
          marginTop: "-8px",
        }}>
          Voltar
        </Typography>
        <Typography variant="h6" sx={{ 
          fontWeight:4600, 
          fontSize: "18px", 
          color: "#8893A9" ,
          marginTop: "-8px",
          gap:"20px",
        }}>
          Disciplina
        </Typography>
      </Box>
    </Box>
    <div
      style={{
        backgroundColor: "white",
        padding: "80px",
        paddingTop: "55px",
      }}
    >
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          if (id) {
            updateItem(values);
          } else {
            createItem(values);
          }
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form>

            <Typography variant="h1">
              {isUpdate ? values.name : i18n.t("others.NewS")}{" "}
            </Typography>
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item md={5}>
                <Textfield
                  name="name"
                  label={i18n.t("others.name")}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item md={5}  >
                <Textfield
                  name="acronym"
                  label={i18n.t("others.Sigla")}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={10}>
                <Textfield
                  name="description"
                  label={i18n.t("others.description")}
                  disabled={isDisabled}
                  multiline
                />
              </Grid>
              <Grid container spacing={2}>
              <Grid item xs={10}>
                <div
                      style={{ display: "flex", justifyContent: "flex-end",gap: "10px" ,paddingTop: "100px",}}
                >
                  
                    <MainButton
                      url="/subjects"
                      name={i18n.t("button.cancel")}
                    />
                
                    <AlertDialog
                      button={
                        isUpdate
                          ? i18n.t("button.update")
                          : i18n.t("button.register")
                      }
                      goBack={i18n.t("subjects.title")}
                      head={textBox[0]}
                      body={
                        textBox[1] || (
                          <FallBackLoader isLoading={status.isLoading} />
                        )
                      }
                      urlGoBack="/subjects"
                      stay={i18n.t("subjects.titleCreate")}
                      urlStay="/subjects/create"
                      isValid={true}
                      type="submit"
                      disabled={isDisabled}
                    />
                
                </div>
              </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
    </>

  );
}
