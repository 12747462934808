import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Button,
  Box,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import "./School.css";
import DateTimePicker from "../../components/form-components/date-time-picker";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import { MainButton } from "../../components/main-button/MainButton";
import CourseSelectorModal from "./SchoolYearForm/modal";
import { useNavigate, useParams } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import TeacherSelectorModal from "./SchoolYearForm/teacher";
import ButtonWithTitle from "../../components/styleButton/styleButton";
import CollapsibleTeste from "../../components/collapsiTable/CollapsibleTeste";
import Collapsible from "../../components/collapsiTable/Collapsible";
import HandleUrl from "../../utils/HandleUrl";
import * as Yup from "yup";
import informati from "../../assets/informati.svg";
import { Api } from "../../services/api";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import axios from "axios";
const stepContainerStyle = {
  display: "flex",
  justifyContent: "center",
};

const customStepLabelStyle = {
  background: "#EFEFEF",
  fontWeight: "bold",
  margin: "0",
  padding: "0",
};

const customStepperStyle = {
  border: "1px solid gray",
  borderRadius: "4px",
  padding: "0", // Remova o padding
  margin: "0",
  marginLeft: "32px",
  minHeight: "200px",
  overflowY: "auto",
};

const selectedStepStyle = {
  background: "white",
  color: " #FFC107",
};



const CustomStepIcon = () => {
  return null;
};

const CustomConnector = () => {
  return null;
};

export default function SchoolYearListingCreatePage() { 
  const [activeStep, setActiveStep] = useState(0);
  let showCourseSection = false;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disciplinesEdit, setDisciplinesEdit] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [addCourseToSchoolYearPayload, setAddCourseToSchoolYearPayload] =
    useState([]);
  const [addCourseToSchoolYear, setAddCourseToSchoolYear] = useState([]);
  const [schoolYearData, setSchoolYearData] = useState([]);
  const [isTeacherModalOpen, setIsTeacherModalOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [disciplinaPayload, setDisciplinaPayload] = useState([]);
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" });
  const handleClic = (m, s) => {
    setToast({ message: m, severity: s });

    setTimeout(() => setOpen(true), 200);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [isCollapsibleTableVisible, setIsCollapsibleTableVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [employeesIncomingData, setEmployeesIncomingData] = useState([]);
  const [subjectssIncomingData, setSubjectssIncomingData] = useState([]);
  const [coordenaIncomingData, setCoordenaIncomingData] = useState([]);
  const navigate = useNavigate(); // Hook para navegação

  const [showCollapsibleTable, setShowCollapsibleTable] = useState(false);
  const extractCellContent = (item) => item.name;
  const [currentTeacher, setCurrentTeacher] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { id } = useParams();
  const [createdYearId, setCreatedYearId] = useState(parseInt(id, 10));
  let isDisabled = HandleUrl();
  const { keycloak } = useKeycloak();
  let schoolYearDisabled = true;
  const [iNITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({

    startDate: "",
    endDate: "",
  });

  const selectStep = (step) => {
    setActiveStep(step);
  };
  const handleClick = () => {
    // Lógica que você deseja executar ao clicar
    setIsCollapsibleTableVisible(true);

    // Desabilita o botão após clicar
    setIsButtonDisabled(true);
  };

  const solveDate = (values) => {
    let d = new Date(values.startDate);
    let EndDate = new Date(values.endDate);
    return (
      values.startDate &&
      d.getFullYear() + (values.endDate && "-" + EndDate.getFullYear())
    );
  };

  const upDateDiscipline = (values) => {
    if (disciplinesEdit)
      setDisciplinesEdit(false);
    disciplinaPayload.forEach((payload, i) => {

      Api.post(
        "/teacher-semester-subject-plan/add-coordinator/teacher-or-coordinator",
        payload,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      )
        .then(function (response) {
          if (disciplinaPayload.length == i + 1)
            getSubjects();

        })
        .catch(function (error) {
          console.error("Error:", error);
          handleClic("Erro:", "error");
        });

    })
  };

  function createSchoolYear(values) {
    const formData = {
      duration: {
        startDate: values.startDate,
        endDate: values.endDate,
      },
      //addCourseToSchoolYearPayload: id ? addCourseToSchoolYearPayload : addCourseToSchoolYearPayload.filter(csp => !csp.courseCoordinationId),
      addCourseToSchoolYearPayload,
    };
    console.log("DATA: ", formData, addCourseToSchoolYearPayload);

    setErrorMessages([]);

    console.log("JSLFJLSDFJASFDLA: ", !!id, createdYearId)
    const isUpdating = (!!id || createdYearId); // Verifica se é uma atualização

    (isUpdating ? Api.put : Api.post)(
      "school-year" + (isUpdating ? "/" + (id || createdYearId) : ""),
      formData,
      {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      }
    )
      .then(function (response) {
        const ID = response.data;
        console.log("DEVERIA FUNCIONAR ASSIM: ", response.data)

        const successMessage = isUpdating
          ? "Ano letivo atualizado com sucesso" // Mensagem para atualização
          : "Ano letivo criado com sucesso"; // Mensagem para criação
        handleClic(successMessage, "success");

        setCreatedYearId(ID || id);
        getSchoolYearById(response.data || id);
        setShowContent(false);
      })
      .catch(function (error) {
        console.error("Error:", error);
        let errorMessage = "Houve um erro ao criar o ano letivo, tente novamente.";
        let statusMessage = "error";

        if (axios.isAxiosError(error)) {
          errorMessage = error.response.data.message?.message;
          if (error.response.status === 500) {
            errorMessage = isUpdating
              ? "Ano letivo atualizado com sucesso" // Mensagem para atualização
              : "Ano letivo criado com sucesso"; // Mensagem para criação
            statusMessage = "success"
          }
        }



        handleClic(errorMessage, statusMessage);

      });
  }

  const calculateDifferenceYear = (startYear, endYear) => {
    return endYear - startYear;
  }

  const validateDates = (startDate, endDate) => {
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();

    if ((startYear >= endYear) || calculateDifferenceYear(startYear, endYear) !== 1) {
      return `Formato do ano letivo inválido`
    }

    Api.get(`${apiUrl}/school-year`, {
      headers: { Authorization: "Bearer " + keycloak.token },
    }).then(response => {
      const dataResponse = response.data.data || response.data;
      if (dataResponse || Array.isArray(dataResponse)) {
        response.data.data.map(data => {
          const responseStartYear = new Date(data.startDate).getFullYear();
          const responseEndYear = new Date(data.endDate).getFullYear();
          if (startYear === responseStartYear && endYear === responseEndYear) {
            return `O ano letivo ${startYear}-${endYear} já está em andamento. Escolha outro período.`;
          }
        })
      }
    });

    return null;
  };

  useEffect(() => {
    if (id) {
      Api
        .get(`${apiUrl}/school-year/${id}`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then((response) => {
          const formattedStartDate = response.data.startDate.split("T")[0];
          const formattedEndDate = response.data.endDate.split("T")[0];

          setINITIAL_FORM_STATE({
            ...response.data, // assumindo que response.data contenha outros campos
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          });
        })
        .catch((error) => {
          console.error("Erro ao buscar dados do funcionário:", error);
        });
    }
  }, [id]);

  const getSchoolYearById = async (createdYearId) => {
    try {
      const response = await Api.get(
        `${apiUrl}/coordination/school-year-id/${createdYearId}`,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      // Usando setSchoolYearData para atualizar o estado com os novos dados
      const AddYear = (c) => {
        let result = [];

        for (
          let index = 0;
          c.course[0] && index < c.course[0].totalNumberOfYears;
          index++
        ) {
          const element = c.courseYearsCoordinators.find(
            (c) => c.courseYear.id === index + 1
          );

          if (element) result.push(element);
          else
            result.push({
              courseYear: {
                id: index + 1,
                value: index + 1 + "º",
              },
              courseYearCoordinator: {
                id: null,
              },
            });
        }

        return result;
      };
      setSchoolYearData([
        ...response.data.data.map((c) => ({
          ...c,
          courseYearsCoordinators: AddYear(c),
        })),
      ]);
      setAddCourseToSchoolYear([])
      setAddCourseToSchoolYearPayload((pl) => {

        console.log("xato", response.data.data);
        pl = response.data.data.reduce((a, c) => {
          if (c.course[0]?.id)
            a.push({
              courseCoordinationId: c.id,
              courseId: c.course[0]?.id,
              courseCoordinatorId: c?.coordinator?.id,
              courseYearsIdAndCoordinatorsId: c.courseYearsCoordinators.map(
                (cy) => ({
                  courseYearCoordinationId: cy.id,
                  courseYearId: cy.courseYear?.id,
                  courseYearCoordinatorId: cy.courseYearCoordinator?.id,
                })
              ),
            });
          return a;
        }, []);
        console.log("pl", pl);
        return pl;
      });
      setShowCollapsibleTable(true);
    } catch (error) {
      console.error("Erro ao obter dados do ano letivo:", error);
    }
  };
  useEffect(() => {

  }, [addCourseToSchoolYear]);

  const getTeachers = async () => {
    try {
      const response = await Api.get(
        `${apiUrl}/teacher-semester-subject-plan/teacher/school-year/${createdYearId}`,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao obter dados dos professores:", error);
      throw error;
    }
  };

  const renoveDuplicatesinArray = async (arr) => {
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (!newArr.find((na) => na.id == arr[i].id)) {
        newArr.push(arr[i]);
      }
    }
    return newArr;
  };

  const getCourses = async (teacherId) => {
    try {
      const response = await Api.get(
        `${apiUrl}/teacher-semester-subject-plan/teacher/${teacherId}/school-year/${createdYearId}`,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      return renoveDuplicatesinArray(response.data);
    } catch (error) {
      console.error("Erro ao obter dados dos cursos:", error);
      throw error;
    }
  };

  const getSubjes = async (teacherId, courseId) => {
    try {
      const response = await Api.get(
        `${apiUrl}/teacher-semester-subject-plan/teacher/${teacherId}/course/${courseId}/school-year/${createdYearId}`,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao obter dados das disciplinas:", error);
      throw error;
    }
  };

  const getCoordinatorData = async () => {
    try {
      console.log("Iniciando a função getCoordinatorData");
  
      // Obtém os professores
      const teachers = await getTeachers();
      console.log("Dados de professores obtidos:", teachers);
  
      // Formata os dados dos professores
      const formattedData = await Promise.all(
        teachers.map(async (teacher) => {
          console.log("Processando professor:", teacher);
  
          // Obtém os cursos do professor
          const courses = await getCourses(teacher.teacher.id);
          console.log(`Cursos do professor ${teacher.teacher.id}:`, courses);
  
          // Obtém as disciplinas dos cursos
          const coursesWithSubjects = await Promise.all(
            courses.map(async (course) => {
              console.log(`Processando curso ${course.id}:`, course);
  
              const subjects = await getSubjes(teacher.teacher.id, course.id);
              console.log(`Disciplinas do curso ${course.id}:`, subjects);
  
              return {
                ...course,
                subjects,
              };
            })
          );
  
          return {
            teacher,
            courses: coursesWithSubjects,
          };
        })
      );
  
      console.log("Dados formatados:", formattedData);
  
      // Atualiza o estado com os dados formatados
      setEmployeesIncomingData(formattedData);
      console.log("Estado atualizado com os dados:", formattedData);
    } catch (error) {
      console.error("Erro ao obter dados dos coordenadores:", error);
    }
  };
  
  // Log fora da função para verificar o estado
  console.log("Employee incomingData fora da função: ", employeesIncomingData);
  

  const getSubjects = async () => {
    try {
      const response = await Api
        .get(`${apiUrl}/school-year/${createdYearId}/subjects`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        })
        .then((response) => {
          // Update the state with the fetched data
          setSubjectssIncomingData(
            response.data.data ? response.data.data : response.data
          );
        });
    } catch (error) {
      console.error("Erro ao obter dados do ano letivo:", error);
    }
  };
  const getCourdena = async () => {
    try {
      const response = await Api.get(
        `${apiUrl}/employees/coordenators`,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      setCoordenaIncomingData(response.data);
    } catch (error) {
      console.error("Erro ao obter dados dos cursos:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (createdYearId) {

      getSchoolYearById(createdYearId);
      getSubjects();
      getCourdena();
    }
  }, [createdYearId]);

  useEffect(() => {
    if (createdYearId) {

      getSchoolYearById(createdYearId);

      getCoordinatorData();
    }
  }, [createdYearId]);

  const getGraduationList = (
    data = [...schoolYearData, ...addCourseToSchoolYear]
  ) => {
    let result = [];
    data.forEach((da) => {
      if (
        da.course.length > 0 &&
        da.course[0].graduation &&
        !result.includes(da.course[0].graduation)
      ) {
        result.push(da.course[0].graduation);
      }
    });

    return result;
  };
  function getTeacherName(teachersSemesterSubjectAssignments) {
    if
      (teachersSemesterSubjectAssignments?.teacher) {
      return `${teachersSemesterSubjectAssignments.teacher.firstName} ${teachersSemesterSubjectAssignments.teacher.lastName}`;
    }
    return "";
  }

  return (
    <>
     <Box sx={{ 
      backgroundColor: "#FFFFFF", 
      marginRight:"10px",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", 
      padding: "20px", 
      marginTop: "-30px",  
      width: "1084px !important",
      top: "100px",  // Ajuste para controlar a posição vertical
 
      marginLeft: "-5px", 
      height:"10px",  // Ajuste para garantir que a altura seja dinâmica
      position: "absolute", // Posicionamento absoluto para ficar por cima
      zIndex: 1000, // Garante que a Box fique acima de outros elementos
    }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
        <IconButton sx={{ 
          color: "#FBC02D", 
          marginRight: "0px", 
          display: "flex", 
          alignItems: "center", 
        }}
        onClick={() => navigate('/SchoolYear')}

        >
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-5px " , marginTop: "-8px", }} />
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px " , marginTop: "-8px", }} />
        </IconButton>
        <Typography variant="h6" sx={{ 
          fontWeight: 400, 
          fontSize: "18px", 
          color: "#FBC02D", 
          marginRight: "10px", 
          marginTop: "-8px",
        }}>
          Voltar
        </Typography>
        <Typography variant="h6" sx={{ 
          fontWeight:4600, 
          fontSize: "18px", 
          color: "#8893A9" ,
          marginTop: "-8px",
          gap:"20px",
        }}>
          Ano Lectivo
        </Typography>
      </Box>
    </Box>
      <Grid className="school">
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleClose}
          sx={{ top: "20px" }}
        >
          <Alert
            onClose={handleClose}
            severity={toast?.severity}
            sx={{ width: "100%" }}
          >
            {toast?.message}
          </Alert>
        </Snackbar>
        <Formik
          initialValues={{
            ...iNITIAL_FORM_STATE,
          }}
          validationSchema={Yup.object({
            startDate: Yup.date().required("Data de início é obrigatória"),
            endDate: Yup.date()
              .required("Data de fim é obrigatória")
              .min(Yup.ref("startDate"), "Data de fim não pode ser antes da data de início"),
          })}
          onSubmit={(values, { setErrors }) => {
            console.log("MANDANDO OS VALORES: ", values)
            const dateError = validateDates(values.startDate, values.endDate);
            if (dateError) {
              setErrors({ startDate: dateError, endDate: dateError });
              return;
            }
            if (activeStep === 0) createSchoolYear(values);
            else if (activeStep === 1) upDateDiscipline(values);
          }}
          enableReinitialize
        >
          {({ values, errors }) => (
            <Form>
              <Typography variant="h1">{i18n.t("others.NewYS")}</Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Textfield
                    name="schoolYear"
                    label={i18n.t("others.schoolYear")}
                    value={solveDate(values)}
                    disabled={schoolYearDisabled}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <DateTimePicker
                    name="startDate"
                    label="Data início"
                    value={values.startDate}
                    disabled={isDisabled}
                    error={Boolean(errors.startDate)}
                    helperText={errors.startDate}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <DateTimePicker
                    name="endDate"
                    label="Data fim"
                    value={values.endDate}
                    disabled={isDisabled}
                    error={Boolean(errors.endDate)}
                    helperText={errors.endDate}
                  />
                </Grid>
                <Grid
                  item
                  xs={5.2}
                  style={{ marginBottom: "40px", marginTop: "10px" }}
                >
                  <ButtonWithTitle
                    title="Importar dados do ano anterior"
                    onClick={handleClick}
                    isDisabled={isButtonDisabled || isDisabled}
                  />
                </Grid>
              </Grid>

              <br />
              <br />
              <Grid container spacing={4}>
                <Grid item xs={10.3} style={customStepperStyle}>
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<CustomConnector />}
                  >
                    {[0, 1, 2].map((index) => (
                      <Step key={index} sx={{ padding: "0" }}>
                        <StepLabel
                          StepIconComponent={CustomStepIcon}
                          onClick={() => selectStep(index)}
                          style={{
                            ...customStepLabelStyle,
                            backgroundColor:
                              activeStep === index ? "white" : "#EFEFEF", // Branco se estiver ativo, cinza caso contrário


                          }}
                        >
                          {index === 0 && "Curso"}
                          {index === 1 && "Disciplina"}
                          {index === 2 && "Professor"}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  {activeStep === 0 && (
                    <div style={selectedStepStyle}>
                      {showCourseSection && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <MainButton
                            url="/exams-list"
                            name={i18n.t("button.cancel")}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: isCollapsibleTableVisible
                            ? "flex-end"
                            : "center",
                          marginBottom: "20px",
                          paddingTop: "40px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          disabled={isDisabled}
                          sx={{
                            height: "30px",
                            padding: "10px",
                            color: "#FFC107",
                            backgroundColor: "#FFFFFF",
                            borderColor: "#FFC107",
                            "&:hover": {
                              color: "#FFC107",
                              backgroundColor: "#FFFFFF",
                              borderColor: "#FFC107",
                            },
                          }}
                          onClick={() => setIsModalOpen(true)}
                        >
                          {i18n.t("button.addC")}
                        </Button>
                      </div>
                    </div>
                  )}

                  {activeStep === 0 &&
                    schoolYearData &&
                    addCourseToSchoolYear && (
                      <div style={selectedStepStyle}>
                        {getGraduationList().map((d) => (
                          <CollapsibleTeste
                            key={d}
                            title={d}
                            secondTitle={
                              [
                                ...schoolYearData,
                                ...addCourseToSchoolYear,
                              ]?.filter((c) => c.course[0]?.graduation === d) ||
                              []
                            }
                            tableData=""
                            setAddCourseToSchoolYearPayload={
                              setAddCourseToSchoolYearPayload
                            }
                            setShowContent={setShowContent}
                            showContent={showContent}
                            addCourseToSchoolYearPayload={
                              addCourseToSchoolYearPayload
                            }
                          />
                        ))}
                      </div>
                    )}
                  {activeStep === 1 && (
                    <div style={{ width: "100%" }}>
                      <Collapsible
                        title="Top Level"
                        items={getGraduationList(subjectssIncomingData).map(
                          (g) => ({
                            edit: () => setDisciplinesEdit(!disciplinesEdit),
                            name: g,
                            items: subjectssIncomingData
                              ?.filter((c) => c.course[0]?.graduation === g)
                              ?.map((c) => ({
                                name: c.course[0].name,
                                items: Array.apply(
                                  null,
                                  Array(c.course[0].totalNumberOfYears)
                                ).map((_, yearIndex) => ({
                                  name: yearIndex + 1 + "º ano",
                                  items: Array.from(
                                    { length: 2 },
                                    (_, semesterIndex) => {
                                      const semesterNumber =
                                        yearIndex * 2 + semesterIndex + 1;
                                      return {

                                        name: `${semesterNumber} º semestre`,
                                        items:
                                          c.course[0].courseSemesterSubjects
                                            ?.filter(
                                              (subject) =>
                                                subject.semester ===
                                                semesterNumber
                                            )
                                            ?.map((subject) => ({
                                              name: (
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    height: "30px",
                                                    alignContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {subject.subject.name} -{disciplinesEdit ? (
                                                    <Select
                                                      defaultValue={subject.subject
                                                        .teachersSemesterSubjectAssignments?.teacher.id}
                                                      name="coordinator"
                                                      onChange={(e) => setDisciplinaPayload(dp => {
                                                        if (dp.find(d => d.courseSemesterSubjectId == subject.subject.id)) {
                                                          dp.find(d => d.courseSemesterSubjectId == subject.subject.id).employeeId = e.target.value;

                                                        } else {
                                                          dp.push({

                                                            "schoolYearId": createdYearId,
                                                            "courseSemesterSubjectId": String(subject.id),
                                                            "employeeId": e.target.value
                                                          })

                                                        }
                                                        return dp
                                                      })}
                                                      sx={{ width: "200px", height: "40px", marginLeft: "10px" }}
                                                      placeholder="Adicionar coordenador"
                                                    >
                                                      {!coordenaIncomingData ? (
                                                        <MenuItem value="" disabled>
                                                          <CircularProgress size={20} />
                                                        </MenuItem>
                                                      ) : (
                                                        coordenaIncomingData.map((teacher) => (
                                                          <MenuItem key={teacher.id} value={teacher.id}>
                                                            {`${teacher.firstName} ${teacher.lastName}`}
                                                          </MenuItem>
                                                        ))
                                                      )}
                                                    </Select>

                                                  ) :
                                                    getTeacherName(
                                                      subject.subject
                                                        .teachersSemesterSubjectAssignments
                                                    ) ? (
                                                      getTeacherName(
                                                        subject.subject
                                                          .teachersSemesterSubjectAssignments
                                                      )
                                                    ) : (
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            "rgba(255, 193, 7, 0.15)",
                                                          padding: "8px",
                                                          borderRadius: "3px",
                                                          marginLeft: "auto",
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <img
                                                          src={informati}
                                                          alt="Visualize"
                                                          style={{
                                                            width: "18px",
                                                            height: "18px",
                                                            color: "#FFC107",
                                                          }}
                                                        />{" "}
                                                        Coordenador em falta
                                                      </div>
                                                    )}
                                                </Box>
                                              ),
                                            })),
                                      };
                                    }
                                  ),
                                })),
                              })),
                          })
                        )}
                        extractCellContent={extractCellContent}
                      />
                    </div>
                  )}
                  {activeStep === 2 && (
                    <div style={selectedStepStyle}>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: isCollapsibleTableVisible
                            ? "flex-end"
                            : "center",
                          marginBottom: "20px",
                          paddingTop: "40px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          disabled={isDisabled}
                          sx={{
                            height: "30px",
                            padding: "10px",
                            color: "#FFC107", // Definindo a cor do texto como amarelo
                            backgroundColor: "#FFFFFF",
                            borderColor: "#FFC107",
                            "&:hover": {
                              color: "#FFC107",
                              backgroundColor: "#FFFFFF",
                              borderColor: "#FFC107",
                              // Adicione outros estilos de hover conforme necessário
                            },
                          }}
                          onClick={() => {
                            setCurrentTeacher(null);
                            setIsTeacherModalOpen(true);
                          }}
                        >
                          {i18n.t("button.addT")}
                        </Button>

                      </Grid>
                      {showCollapsibleTable && (
                        <div style={{ width: "100%", marginTop: "20px" }}>
                          <Collapsible
                            title="Top Level"
                            items={employeesIncomingData?.map(
                              (coordinatorData) => ({
                                name: coordinatorData?.teacher?.teacher.name,
                                edit: () => {
                                  setCurrentTeacher({
                                    ...coordinatorData.teacher.teacher,
                                    ...{ courses: coordinatorData.courses },
                                    teacherAssignmentId:
                                      coordinatorData.teacher
                                        .teacherAssignmentId,
                                  });
                                  setIsTeacherModalOpen(true);
                                },
                                items: coordinatorData?.courses?.map(
                                  (course) => ({
                                    name: course?.name,
                                    items: course?.subjects?.map((subject) => ({
                                      name: `${subject?.subject?.name} - ${subject?.subject?.variant?.name}`,
                                    })),
                                  })
                                ),
                              })
                            )}
                            extractCellContent={extractCellContent}
                            levels={["items", "items"]}
                            style={{ marginTop: "20px" }}
                          />
                          {console.log("13", employeesIncomingData)}
                        </div>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      paddingLeft: "620px",
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>
                      <MainButton
                        url="/SchoolYear"
                        name={i18n.t("button.cancel")}
                        disabled={isDisabled}
                      />
                    </span>
                    <span>
                      <Button
                        variant="contained"
                        disabled={isDisabled}
                        type="submit"
                        sx={{
                          color: "white",
                          padding: "5px",
                          backgroundColor: "#FFC107",
                          "&:hover": { backgroundColor: "#FFA000" },
                        }}
                        color="primary"
                      >
                        { (id || createdYearId) ? i18n.t("button.update") : i18n.t("button.add")}
                      </Button>
                    </span>
                  </div>
                </Grid>
              </Grid>
              {errorMessages.length > 0 && (
                <div style={{ color: "red", marginTop: "10px" }}>
                  {errorMessages.map((message, index) => (
                    <p key={message}>{message}</p>
                  ))}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Grid>
      <CourseSelectorModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={(option) => {
          setSelectedCourse(option);
          setIsModalOpen(false);
        }}
        setAddCourseToSchoolYearPayload={setAddCourseToSchoolYearPayload}
        setAddCourseToSchoolYear={setAddCourseToSchoolYear}
        addCourseToSchoolYearPayload={addCourseToSchoolYearPayload}
      />
      <TeacherSelectorModal
        open={isTeacherModalOpen}
        onClose={() => setIsTeacherModalOpen(false)}
        onConfirm={(option) => {
          setSelectedCourse(option);
          setIsModalOpen(false);
        }}
        coordinate={getCoordinatorData}
        createdYearId={createdYearId}
        currentTeacher={currentTeacher}
      />
    </>
  );
}
