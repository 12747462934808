import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Alert,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useParams } from 'react-router-dom';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import axios from 'axios';
import { id } from 'date-fns/locale';
import CloseIcon from '@mui/icons-material/Close';

import { i18n } from '../../translate/i18n';
import { useKeycloak } from '@react-keycloak/web';
const SchedulePage = () => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [strands, setStrands] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [selectedStrand, setSelectedStrand] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [turmas, setTurmas] = useState([]);
  const [selectedTurma, setSelectedTurma] = useState(null);
  const { cursoId, year, semester } = useParams();
  const [days, setDays] = useState([]);
  const [courseDetails, setCourseDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [schedulerPData, setSchedulerPData] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [hours, setHours] = useState([]);
  const [showMessage, setShowMessage] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [turmaSelected, setTurmaSelected] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selection, setSelection] = useState({
    period: '',
    strand: '',
  });
  const handleDetalhesClick = (id) => {
    navigate(`/disciplinas/${cursoId}`); 
};
  const handleClose = () => {
    setShowMessage(false);
  };

  const extrairNumero = (texto) => {
    if (texto === undefined || texto === null) {
      return null;
    }
    const match = String(texto).match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  };
  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(`/courses/${cursoId}`);
        setCourseDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar detalhes do curso:', error);
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [cursoId]);

  const fetchTurmas = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL.replace(
        /\/$/,
        ''
      )}/classes/${cursoId}/${year}/${semester}`;

      const response = await axios.get(apiUrl);
      const dados = response.data;

      const formattedTurmas = dados.map((turma) => ({
        id: turma.id,
        name: turma._name,
      }));

      //console.log('Formatted Turmas:', formattedTurmas);

      setTurmas(formattedTurmas);
    } catch (error) {
      console.error('Erro ao buscar turmas:', error);
    }
  };

  const fetchDays = async () => {
    try {
      const response = await axios.get('/teaching-days');
      setDays(response.data);
    } catch (error) {
      console.error('Erro ao buscar os dias da semana:', error);
    }
  };
  useEffect(() => {
    fetchDays();
  }, []);

  const fetchHours = async () => {
    try {
      const response = await axios.get(
        '/teaching-time/period/2b1755f9-9005-4d84-aa2f-c31ed6faad43'
      );
      setHours(response.data);
    } catch (error) {
      console.error('Erro ao buscar horários:', error);
    }
  };

  useEffect(() => {
    fetchHours();
  }, []);
  useEffect(() => {
    fetchTurmas();
  }, [cursoId, year, semester]);
  const handleChange = (event) => {
    setSelectedTurma(event.target.value);
  };
  useEffect(() => {
    const fetchStrandsAndPeriods = async () => {
      try {
        const [strandsResponse, periodsResponse] = await Promise.all([
          axios.get('/strand'),
          axios.get('/period'),
        ]);
        setStrands(strandsResponse.data);
        setPeriods(periodsResponse.data);
      } catch (error) {
        console.error('Erro ao buscar vertentes e períodos:', error);
      }
    };

    fetchStrandsAndPeriods();
  }, []);

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const [schedulesResponse, schedulerPResponse] = await Promise.all([
          axios.get(
            `/scheduler/course-schedules/${cursoId}/${year}/${semester}`
          ),
          axios.get(
            `/scheduler-p/course-schedules-p/${cursoId}/${year}/${semester}`
          ),
        ]);
        console.log('Response from scheduler-p:', schedulerPResponse); 

        setSchedules(schedulesResponse.data.data || []);
        setSchedulerPData(schedulerPResponse.data.data || []);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados de agendamento:', error);
        setLoading(false);
      }
    };

    fetchSchedules();
  }, [cursoId, year, semester]);

  if (loading) {
    return <div>Carregando...</div>;
  }
  //console.log('Schedules:', schedules, schedulerPData);
  const combinedData = [
    ...schedules.map((schedule) => ({
      id: schedule.id,
      discipline: schedule.schedulerDetails?.[0]?.subject?.name || 'N/A',
      strand: schedule.strand || 'N/A',
      period: schedule.period || 'N/A',
    })),
    ...schedulerPData.map((data) => ({
      id: data.id,
      discipline: data.schedulerDetails?.[0]?.subject?.name || 'N/A',
      strand: data.strand || 'N/A',
      period: data.period || 'N/A',
    })),
  ];

  const handleCellClick = (data, i) => {
    //console.log('CellClick', data);
    setSelectedTurma(data.discipline || '');
    setSelectedStrand(data.strand.name || '');
    setSelectedPeriod(data.period.name || '');
    getDisciplineData(data.discipline);
    setSelectedIndex(i);
  };

  //console.log('Combined data:', combinedData);
  const handleUpdateSchedule = async () => {
    if (!selectedTurma || !selectedStrand || !selectedPeriod) {
      setErrorMessage('Por favor, preencha todos os campos.');
      return;
    }
  
    const schedule = schedules[selectedIndex];
    if (!schedule) {
      setErrorMessage('Nenhum horário selecionado para atualizar.');
      return;
    }
  
    let payload;
    let endpoint;
  
    if (selectedStrand === 'Teórica') {
      endpoint = `/scheduler/${schedule.id}`;
      payload = {
        year: parseInt(turmaSelected?.year),
      courseId: schedule.course?.id,
      semester: parseInt(turmaSelected?.semester),
      strandId: selection?.strand?.id,
      periodId: selection?.period?.id,
        scheduleDetails: [
          {
            subjectId: `${turmaSelected?.subject?.id}`,
            dayId: schedule.schedulerDetails[0]?.teachingDays?.id,
            timeId: schedule.schedulerDetails[0]?.teachingTime?.id,
            teacherId: schedule.schedulerDetails[0]?.teacher?.id,
            classroomId: `${schedule.schedulerDetails[0]?.classroom?.id}`,
          },
        ],
      };
    } else 
    if (selectedStrand === 'Prática') {
      
      endpoint = `/scheduler-p/${schedule.id}`;
      payload = {
        year: parseInt(turmaSelected?.year, 10),
    courseId: schedule.course?.id,
    semester: parseInt(turmaSelected?.semester, 10), 
    strandId: selection?.strand?.id,
    periodId: selection?.period?.id,
    subjectId: parseInt(turmaSelected?.subject?.id, 10), 
        scheduleDetails: [
          {
            dayId: schedule.schedulerDetails[0]?.teachingDays?.id,
            timeId: schedule.schedulerDetails[0]?.teachingTime?.id,
            teacherId: schedule.schedulerDetails[0]?.teacher?.id,
            groupId: String(schedule.schedulerDetails[0]?.group?.id),
            classroomId: String(schedule.schedulerDetails[0]?.classroom?.id),
          },
        ],
      };
      console.log("Payload enviado:", JSON.stringify(payload, null, 2));

    } else {
      setErrorMessage('Vertente inválida selecionada.');
      return;
    }
  
    try {
      const response = await axios.put(endpoint, payload);
      if (response.status === 200) {
        setSuccessMessage('Horário atualizado com sucesso!');
      }
    } catch (error) {
      console.error('Erro ao atualizar o horário:', error);
      setErrorMessage('Erro ao atualizar o horário. Tente novamente.');
    }
  };
  
  
  

  async function getDisciplineData(e) {
    let disciplines = courseDetails?.classes?.filter(
      (elemento) => elemento._name == e
    );

    setTurmaSelected(disciplines[0]);
  }

  return (
    <Box
      sx={{
        backgroundColor: '',
        height: '100vh',
        padding: '-300px',
        width: '1220px !important',

        maxWidth: '1200000px',
        margin: '0 auto',
      }}
    >
      {/* Cabeçalho */}
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          marginRight: '10px',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '',
          padding: '20px',
          marginTop: '-30px',
          width: '1213px !important',
          marginLeft: '-40px',
          height: '10px',
        }}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
        >
          {/* Ícone de voltar com duas setas */}
          <IconButton
            sx={{
              color: '#FBC02D',
              marginRight: '0px',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleDetalhesClick}
          >
            {/* Primeira seta com tamanho reduzido */}
            <ArrowBackIosNewIcon
              sx={{ fontSize: '18px', marginLeft: '-5px ', marginTop: '-8px' }}
            />
            <ArrowBackIosNewIcon
              sx={{ fontSize: '18px', marginLeft: '-10px ', marginTop: '-8px' }}
            />
          </IconButton>
          <Typography
            variant='h6'
            sx={{
              fontWeight: 400,
              fontSize: '18px',
              color: '#FBC02D',
              marginRight: '10px',
              marginTop: '-8px',
            }}
          >
            Voltar
          </Typography>
          {/* Texto "Horário" em cinza */}
          <Typography
            variant='h6'
            sx={{
              fontWeight: 4600,
              fontSize: '18px',
              color: '#8893A9',
              marginTop: '-8px',
              gap: '20px',
            }}
          >
            CLEMENTINA
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '',
          padding: '20px',
          marginTop: '15px',
          width: '1220px !important',
          marginLeft: '-40px',
          height: '630px',
        }}
      >
        <Typography
          variant='h6'
          sx={{ fontWeight: 600, fontSize: '18px', color: '#333333' }}
        >
          Horário
        </Typography>

        {/* Filtros */}
        <Box
          sx={{
            border: '1px solid #D6DEE6',
            width: '100%',
            maxWidth: '107000px',
            height: '60px',
            margin: '0 auto',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2px',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              padding: '0px',
              width: '100%',
              margin: '0 auto',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '10px',
                marginTop: '2px',
              }}
            >
              {/* Seleção de Turma */}
              <FormControl
                sx={{
                  minWidth: '140px',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                }}
              >
                <InputLabel sx={{ fontSize: '14px', color: '#8893A9' }}>
                  Turma
                </InputLabel>
                <Select
                  value={selectedTurma}
                  onChange={(e) => {
                    getDisciplineData(e.target.value);
                    setSelectedTurma(e.target.value);
                  }}
                  input={<OutlinedInput />}
                  sx={{ borderRadius: '6px', height: '40px' }}
                >
                  {turmas.map((turma) => (
                    <MenuItem key={turma.id} value={turma.name}>
                      {turma.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Seleção de Vertente */}
              <FormControl
                sx={{
                  minWidth: '140px',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                }}
              >
                <InputLabel sx={{ fontSize: '14px', color: '#8893A9' }}>
                  Vertente
                </InputLabel>
                <Select
                  value={selectedStrand}
                  onChange={(e) => {
                    setSelectedStrand(e.target.value);
                    const index = strands.findIndex(
                      (strand) => strand.name === e.target.value
                    );
                    setSelection({ ...selection, strand: strands[index] });
                  }}
                  input={<OutlinedInput />}
                  sx={{ borderRadius: '6px', height: '40px' }}
                >
                  {strands.map((strand, i) => (
                    <MenuItem key={strand.id} value={strand.name}>
                      {strand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Seleção de Período */}
              <FormControl
                sx={{
                  minWidth: '140px',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                }}
              >
                <InputLabel sx={{ fontSize: '14px', color: '#8893A9' }}>
                  Período
                </InputLabel>
                <Select
                  value={selectedPeriod}
                  onChange={(e) => {
                    setSelectedPeriod(e.target.value);
                    const index = periods.findIndex(
                      (period) => period.name === e.target.value
                    );
                    setSelection({ ...selection, period: periods[index] });
                  }}
                  input={<OutlinedInput />}
                  sx={{ borderRadius: '6px', height: '40px' }}
                >
                  {periods.map((period) => (
                    <MenuItem key={period.id} value={period.name}>
                      {period.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Botão Criar Horário */}
            <Box
              sx={{
                display: 'flex',
                color: '#FFF',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button
                variant='contained'
                sx={{
                  backgroundColor: '#FBC02D',
                  color: 'white',
                  fontWeight: 400,
                  height: '33px',
                  paddingRight: '-300px',
                  marginRight: '10px',
                  textTransform: 'none',

                  borderRadius: '6px',
                  marginTop: '-40px',
                  ':hover': { backgroundColor: '#FBC02D' },
                }}
                onClick={() => navigate('')}
              >
                Actualizar Horário
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Grade de horários */}
        <Box sx={{ marginTop: '30px' }}>
          {/* Cabeçalho da tabela */}
          <Box
            sx={{
              display: 'grid',
              width: '100%',
              gridTemplateColumns: `80px repeat(${days.length}, 1fr)`,
              textAlign: 'center',
              backgroundColor: '#FFFFFF',
              fontWeight: 600,
              fontSize: '14px',
              marginBottom: '20px',
              color: '#8893A9',
            }}
          >
            <Typography></Typography>
            {days.map((day, index) => (
              <Typography key={index}>{day.name}</Typography>
            ))}
          </Box>

          {/* Linha de horários */}
          <Box
            sx={{
              display: 'grid',

              gridTemplateColumns: '80px repeat(7, 1fr)',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            {/* Horários na lateral */}
            <Box>
              {['07:30', '08:30', '09:30', '10:30', '11:30', '12:30'].map(
                (time, index) => (
                  <Typography
                    key={index}
                    sx={{
                      textAlign: 'center',
                      fontSize: '14px',
                      backgroundColor: '#FFFFFF',
                      padding: '10px 0',
                      color: '#212529',
                      marginBottom: '35px',
                    }}
                  >
                    {time}
                  </Typography>
                )
              )}
            </Box>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 183px)',
                marginTop: '-30px',
                gap: '0px',
                borderCollapse: 'collapse',
                width: '1100px',
              }}
            >
              {combinedData.map((data, i) => (
                <div
                  key={data.id}
                  style={{
                    border: '1px solid #D6DEE6',
                    padding: '15px',
                    height: '40px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => handleCellClick(data, i)}
                >
                  <Typography
                    variant='body1'
                    sx={{ margin: '0', fontSize: '13px' }}
                  >
                    {data.discipline} {/* Disciplina */}
                  </Typography>

                  <Typography
                    variant='body2'
                    sx={{ margin: '0', fontSize: '13px' }}
                  >
                    {data.strand.name} {/* Strand */}
                  </Typography>

                  <Typography
                    variant='body2'
                    sx={{ margin: '0', fontSize: '13px' }}
                  >
                    {data.period.name} {/* Período */}
                  </Typography>
                </div>
              ))}

              {/* Preencher células vazias para totalizar 36 */}
              {combinedData.length < 36 &&
                [...Array(36 - combinedData.length)].map((_, emptyIndex) => (
                  <div
                    key={`empty-${emptyIndex}`}
                    style={{
                      border: '1px solid #D6DEE6',
                      padding: '15px',
                      height: '40px',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {'\u00A0'}
                  </div>
                ))}
            </div>
          </Box>
        </Box>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            marginTop: '15px',
            marginRight: '20px',
          }}
        >
          <Button
            variant='contained'
            sx={{
              backgroundColor: '#FFF',
              color: '#FBC02D',
              fontWeight: 400,
              height: '40px',
              width: '120px',
              textTransform: 'none',
              borderRadius: '6px',
              ':hover': { backgroundColor: '#FBC02D' },
            }}
            onClick={() => navigate('')}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            sx={{
              backgroundColor: '#FBC02D',
              color: 'white',
              fontWeight: 400,
              height: '40px',
              width: '120px',
              textTransform: 'none',
              borderRadius: '6px',
              ':hover': { backgroundColor: '#FBC02D' },
            }}
            onClick={handleUpdateSchedule}
          >
            Actualizar
          </Button>
        </div>

        {successMessage && (
          <Box sx={{}}>
            <Alert severity='success' icon={false}>
              {successMessage}
              <IconButton onClick={handleClose} sx={{}}>
                <CloseIcon />
              </IconButton>
            </Alert>
          </Box>
        )}
        {/* Mensagem de erro */}
        <Snackbar
          open={!!successMessage || !!errorMessage}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            severity={successMessage ? 'success' : 'error'}
            onClose={handleClose}
          >
            {successMessage || errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default SchedulePage;
