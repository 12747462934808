import React, { useEffect, useState } from "react";
import { Box, CircularProgress, IconButton, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from 'prop-types';
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

function CollapsibleTeste({ title, secondTitle, label, subLabel, tableData, columnsTitle, setAddCourseToSchoolYearPayload, setShowContent, showContent, addCourseToSchoolYearPayload }) {
  const [openI, setOpenI] = useState(false);
  const [graduations, setGraduations] = useState([]);
  const [courses, setCourses] = useState([]);
  const [apiYears, setApiYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employee, setEmployees] = useState([]);

  const { keycloak } = useKeycloak();
  const apiUrl = process.env.REACT_APP_API_URL;

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      });
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  useEffect(() => {
    getList("graduations", setGraduations);
    getList("curricular-plan", setCourses);
    getList("employees/coordenators", setEmployees);

    getList("years", setApiYears);

  }, []);
 
  

  useEffect(() => {
    // Add a style tag to the document's head with the scrollbar styles
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      ::-webkit-scrollbar {+
        height: 0; /* Hide horizontal scrollbar */
      }
    `;
    document.head.appendChild(styleTag);

    // Clean up the style tag when the component unmounts
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []); // Run this effect only once on mount

  const [openRows, setOpenRows] = useState({});

  const handleRowToggle = (index) => {
    setOpenRows((prevOpenRows) => {
      return { ...prevOpenRows, [index]: !prevOpenRows[index] };
    });
  };


  const handleCourseCoordinatorSelection = (courseId, value) => {


    setAddCourseToSchoolYearPayload((sc) => {
      if (sc.find(c => c.courseId === courseId)) {
        sc.find(c => c.courseId === courseId).courseCoordinatorId = value;

        return sc
      } else {
        sc.push({ courseId: courseId, courseCoordinatorId: value});
        return sc;
      }

    })

  };

  const handleYearCoordinatorSelection = (courseId, year, value) => {


    setAddCourseToSchoolYearPayload((sc) => {
      if (sc.find(c => c.courseId === courseId)) {
        const updatedCoordinatorYears = [...(sc.find(c => c.courseId === courseId).courseYearsIdAndCoordinatorsId)];
        if (updatedCoordinatorYears.find(cy => cy.courseYearId == year)) {
          updatedCoordinatorYears.find(cy => cy.courseYearId == year).courseYearId = parseInt(year)
          updatedCoordinatorYears.find(cy => cy.courseYearId == year).courseYearCoordinatorId = value
        } else {
          updatedCoordinatorYears.push({ courseYearId: parseInt(year), courseYearCoordinatorId: value })
        }
        sc.find(c => c.courseId === courseId).courseYearsIdAndCoordinatorsId = updatedCoordinatorYears
        return sc
      }else{
        sc.push({courseId: courseId
        
          , courseCoordinatorId: value
         
        , courseYearsIdAndCoordinatorsId: [{ courseYearId: parseInt(year), courseYearCoordinatorId: value }]})
        return sc
      }
    })




  };

  const handleEditIconClick = () => {
    setShowContent((prevShowContent) => !prevShowContent);

  };

  return (
    <TableContainer sx={{ marginTop: "30px" }} component={Paper}>
      <Table>
        <TableHead style={{ backgroundColor: "#E4EBF5" }}>
          <TableRow>
            <TableCell className="custom-table-cell" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenI(!openI)}
                >
                  {openI ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
                {title}
              </div>
              <div>
                <ModeEditIcon
                  style={{
                    color: "#FFC107",
                    marginTop: "10px",
                    border: "1px solid #FFC107",
                  }}
                  onClick={handleEditIconClick}
                />
              </div>
            </TableCell>
          </TableRow>
        </TableHead>


        {secondTitle?.map((secondTitleItem, index) => (
          <TableHead key={secondTitleItem.someUniqueIdentifier} style={{ backgroundColor: "#F7F9FC", border: "1px solid #E0E0E0" }}>
            <Collapse in={openI}>
              <TableRow>
                <TableCell colSpan={1}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingLeft: "30px",
                    }}
                  >
                    <Box sx={{}}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleRowToggle(index)}
                      >
                        {openRows[index] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                      </IconButton>
                      {label} 
  </Box>
                    <Box sx={{}}>
                      {showContent ? (
                        <Box sx={{}}>
                          {secondTitleItem.course[0]?.name} -
                          <Select
  defaultValue={secondTitleItem?.coordinator?.id}
  name="coordinator"
  onChange={(e) => handleCourseCoordinatorSelection(secondTitleItem.course[0]?.id, e.target.value)}
  sx={{ width: "200px", height: "40px", marginLeft: "10px" }}
  placeholder="Adicionar coordenador"
>
  {loading ? (
    <MenuItem value="" disabled>
      <CircularProgress size={20} />
    </MenuItem>
  ) : (
    employee.map((teacher) => (
      <MenuItem key={teacher.id} value={teacher.id}>
        {`${teacher.firstName} ${teacher.lastName}`}
      </MenuItem>
    ))
  )}
</Select>


                        </Box>
                      ) : (
                        <Box sx={{}}>
                          {secondTitleItem.course[0]?.name}-{secondTitleItem?.coordinator?.firstName} {secondTitleItem?.coordinator?.lastName}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </Collapse>
            <Collapse in={openRows[index]}>
              <Table size="small" style={{ border: "1px solid #E0E0E0", backgroundColor: "#fff" }}>
                <TableHead></TableHead>
                <TableBody>
                  {console.log("testesecond", secondTitleItem)}
                  {secondTitleItem?.courseYearsCoordinators?.map((data) => (
                    <TableRow key={data.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell
                         key={data.id}
                        component="th"
                        scope="row"
                        sx={{
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          fontFamily: "Work Sans",
                          fontSize: "14px",
                          paddingLeft: "80px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        
                        {data.courseYear.value} Ano - {showContent ? (
    <Select
    defaultValue={data?.courseYearCoordinator?.id}
    name="coordinator"
    onChange={(e) => handleYearCoordinatorSelection(secondTitleItem.course[0]?.id, data.courseYear.id, e.target.value)}
    sx={{ width: "200px", height: "40px", marginLeft: "10px" }}
    placeholder="Adicionar coordenador"
  >
    {!employee ? (
      <MenuItem value="" disabled>
        <CircularProgress size={20} />
      </MenuItem>
    ) : (
      employee.map((teacher) => (
        <MenuItem key={teacher.id} value={teacher.id}>
          {`${teacher.firstName} ${teacher.lastName}`}
        </MenuItem>
      ))
    )}
  </Select>
                        ) : (
                          <Box sx={{}}>
                            {data.courseYearCoordinator?.firstName} {data.courseYearCoordinator?.lastName}
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableHead>
        ))}

      </Table>
    </TableContainer>
  );
}

CollapsibleTeste.propTypes = {
  title: PropTypes.string.isRequired,
  secondTitle: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string.isRequired,
  tableData: PropTypes.array.isRequired,
  columnsTitle: PropTypes.array.isRequired,
  setAddCourseToSchoolYearPayload: PropTypes.func.isRequired,
  setShowContent: PropTypes.func.isRequired,
  showContent: PropTypes.bool.isRequired,
  addCourseToSchoolYearPayload: PropTypes.object.isRequired,
};

export default CollapsibleTeste;