import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import {useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useEffect,useState  } from "react";
import { useKeycloak } from "@react-keycloak/web";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { getItem as get } from "../../utils/functions/tableCRUDFunctions";
import {
  createItem as create,
  updateItem as update,
} from "../../utils/functions/formCRUDFunctions";

export default function GraduationCreatePage() {
  let isDisabled = HandleUrl();

  const { keycloak } = useKeycloak();
  let page =1;
  const apiUrl = `${process.env.REACT_APP_API_URL}/graduations`;
  const [INITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({});
  const { id } = useParams();
  const isUpdate = !!id;
  const [status, setStatus] = useState({
    isLoading: true,
  });
  const navigate = useNavigate(); // Hook para navegação

  const [textBox, setTextBox] = useState([
    i18n.t(id ? "messages.updateTitle" : "messages.createTitle"),
    "",
  ]);

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required(i18n.t("messages.required")),
    description: Yup.string().required(i18n.t("messages.required")),
  });

  const getItem = get(
    `${apiUrl}/${id}`,
    keycloak,
    setINITIAL_FORM_STATE,
    undefined,
    page
  );
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const formatFormData = (values) => ({
    name: values.name,
    description: values.description,
  });

  const createItem = create(formatFormData, apiUrl, setTextBox, setStatus, keycloak, "Graduação criada com sucesso!");
  const updateItem = update(formatFormData, apiUrl, setTextBox, setStatus, keycloak, "Graduação atualizada com sucesso!");
  return (
    <>
     <Box sx={{ 
      backgroundColor: "#FFFFFF", 
      marginRight:"10px",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", 
      padding: "20px", 
      marginTop: "-30px",  
      width: "1089px !important",
      top: "100px",  // Ajuste para controlar a posição vertical
 
      marginLeft: "-5px", 
      height:"10px",  // Ajuste para garantir que a altura seja dinâmica
      position: "absolute", // Posicionamento absoluto para ficar por cima
      zIndex: 1000, // Garante que a Box fique acima de outros elementos
    }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", }}>
        <IconButton sx={{ 
          color: "#FBC02D", 
          marginRight: "0px", 
          display: "flex", 
          alignItems: "center", 
        }}
        onClick={() => navigate('/graduation')}

        >
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-5px " , marginTop: "-8px", }} />
          <ArrowBackIosNewIcon sx={{ fontSize: "18px", marginLeft: "-10px " , marginTop: "-8px", }} />
        </IconButton>
        <Typography variant="h6" sx={{ 
          fontWeight: 400, 
          fontSize: "18px", 
          color: "#FBC02D", 
          marginRight: "10px", 
          marginTop: "-8px",
        }}>
          Voltar
        </Typography>
        <Typography variant="h6" sx={{ 
          fontWeight:4600, 
          fontSize: "18px", 
          color: "#8893A9" ,
          marginTop: "-8px",
          gap:"20px",
        }}>
          Graduação
        </Typography>
      </Box>
    </Box>
      <Grid
        sx={{ backgroundColor: "white", padding: "80px", paddingTop: "55px" }}
      >
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            if (id) {
              updateItem(values);
            } else {
              createItem(values);
            }
          }}
          enableReinitialize
        >
          {({ values, isValid }) => (
            <Form>
              <Typography variant="h1">
                {isUpdate ? values.name : i18n.t("others.NewG")}{" "}
              </Typography>
              <br />

              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <Textfield
                    name="name"
                    label={i18n.t("others.name")}
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item xs={10}>
                  <Textfield
                    name="description"
                    label={i18n.t("others.description")}
                    disabled={isDisabled}
                    multiline
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <div
                       style={{ display: "flex", justifyContent: "flex-end",gap: "10px" ,paddingTop: "100px",}}
                  >
                   
                      <MainButton
                        url="/graduation"
                        name={i18n.t("button.cancel")}
                      />
                    
                      <AlertDialog
                        show={true}
                        button={
                          isUpdate
                            ? i18n.t("button.update")
                            : i18n.t("button.register")
                        }
                        head={textBox[0]}
                        body={
                          textBox[1] || (
                            <FallBackLoader isLoading={status.isLoading} />
                          )
                        }
                        goBack={i18n.t("others.listOfGraduation")}
                        urlGoBack="/graduation"
                        stay={
                          isUpdate
                            ? i18n.t("others.graduation")
                            : i18n.t("graduations.newGrad")
                        }
                        isValid={isValid}
                        urlStay={
                          isUpdate
                            ? `graduation/${id}/edit`
                            : "/graduation/create"
                        }
                        type="submit"
                        disabled={isDisabled}
                      />
                   
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
}
