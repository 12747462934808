import { Box, Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionButtons from '../../components/actions-buttom/ActionButtons';
import CustomPagination from '../../components/pagination';
import { i18n } from '../../translate/i18n';
import { useKeycloak } from '@react-keycloak/web';
import { AddBoxRounded } from '@mui/icons-material';
import AutoSearch from '../../components/autoSearch/AutoSearch';
import FilterList from '../../components/filterList/FilterList';
import Loading from '../../components/loading/loading';
import FallBackCard from '../../components/FallBackComponents/FallBackCard';
import usePagination from '../../utils/hooks/usePagination';
import {
  deleteItem as eliminate,
  editItem as edit,
  visualizeItem,
  getItem as get,
  newItemPage,
} from '../../utils/functions/tableCRUDFunctions';
import { flattenArray } from '../../utils/functions/tableFilterFunctions';

import AlertDialogForConfirm from '../../components/confirmation/AlertDialog';
import { useConfirmDeleteModal } from '../../utils/hooks/useModals';
import { useFilter, useSearch } from '../../utils/hooks/useSearch';
import { debounce } from 'lodash';

export default function ClassListingPage() {
  const [IncomingData, setIncomingData] = useState([]);
  const pathUrl = '/classes';
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const apiUrl = `${process.env.REACT_APP_API_URL}/classes`;
  const newItem = newItemPage(navigate, `${pathUrl}/create`);

  const {
    open,
    deleteItemId,
    message,
    loading,
    setLoading,
    success,
    handleClose,
    handleClickOpen,
    handleSuccess,
    handleBadRequest,
    errorFallback,
  } = useConfirmDeleteModal();

  const analyzeItem = visualizeItem(pathUrl, navigate);

  const editItem = edit(pathUrl, navigate);

  //Table filters
  const [currentFilters, setCurrentFilters] = useState({});

  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedNumberOfGroups, setSelectedNumberOfGroups] = useState([]);
  const [selectedNumberOfStudents, setSelectedNumberOfStudents] = useState([]);

  const extractUniqueValues = (array, key) => {
    return [...new Set(array?.map((item) => item[key]))];
  };

  const flattenedCourse = flattenArray(IncomingData, 'course');
  const flattenedSubject = flattenArray(IncomingData, 'subject');
  const years = extractUniqueValues(IncomingData, 'year');
  const courses = [
    ...new Set(
      extractUniqueValues(flattenedCourse, 'course').map((item) => item?.name)
    ),
  ];
  const semesters = extractUniqueValues(IncomingData, 'semester');
  const subjects = [
    ...new Set(
      extractUniqueValues(flattenedSubject, 'subject').map((item) => item?.name)
    ),
  ];
  const numberOfGroups = extractUniqueValues(IncomingData, 'numberOfGroups');
  const numberOfStudents = extractUniqueValues(
    IncomingData,
    'numberOfStudents'
  );

  const filters = [
    {
      label: i18n.t('others.year'),
      options: years,
      selectedOptions: selectedYear,
      setSelectedOptions: setSelectedYear,
    },
    {
      label: i18n.t('others.course'),
      options: courses,
      selectedOptions: selectedCourse,
      setSelectedOptions: setSelectedCourse,
    },
    {
      label: i18n.t('others.semester'),
      options: semesters,
      selectedOptions: selectedSemester,
      setSelectedOptions: setSelectedSemester,
    },
    {
      label: i18n.t('others.subject'),
      options: subjects,
      selectedOptions: selectedSubject,
      setSelectedOptions: setSelectedSubject,
    },
    {
      label: i18n.t('others.groups'),
      options: numberOfGroups,
      selectedOptions: selectedNumberOfGroups,
      setSelectedOptions: setSelectedNumberOfGroups,
    },
    {
      label: i18n.t('others.numberOfStudents'),
      options: numberOfStudents,
      selectedOptions: selectedNumberOfStudents,
      setSelectedOptions: setSelectedNumberOfStudents,
    },
  ];

  //Search functionality

  const { searchTerm, setSearchTerm } = useSearch();
  const { setCanFilter, canFilter } = useFilter();

  const filteringProps = {
    year: selectedYear,
    course: selectedCourse,
    semester: selectedSemester,
    subject: selectedSubject,
    numberOfGroups: selectedNumberOfGroups,
    numberOfStudents: selectedNumberOfStudents,
    search: searchTerm,
  };
  //Pagination  & Items per page filter
  const items = IncomingData || [];

  const {
    currentPage,
    totalPages,
    setCurrentPage,
    pageSize,
    handlePageSizeChange,
    availablePageSizes,
    handlePaginationData,
  } = usePagination(items, [5, 10, 20, 100]);

  const getItem = get(
    /* `${apiUrl}/class-subject/by-coordinator`, */
    `${apiUrl}`,
    keycloak,
    setIncomingData,
    setIsLoading,
    currentPage,
    'classListPage',
    pageSize,
    handlePaginationData,
    setCurrentFilters,
    false, //canFilter || searchTerm
    null,
    true
  );

  const deleteItem = eliminate(
    apiUrl,
    keycloak,
    getItem,
    setLoading,
    handleSuccess,
    handleBadRequest,
    errorFallback,
    totalPages,
    pageSize,
    'classListPage',
    currentFilters
  );

  const debouncedGetItem = debounce((filters) => getItem(filters), 1000);

  useEffect(() => {
    if (keycloak.token) {
      if (searchTerm) {
        debouncedGetItem(filteringProps);
      } else {
        getItem(filteringProps);
      }
    }
  }, [keycloak.token, currentPage, pageSize, canFilter, searchTerm]);

  useEffect(() => {
    localStorage.clear();
  }, [pageSize]);

  return (
    <>
      <Grid container component={Paper}>
        {isLoading && <Loading />}
        {!isLoading && (
          <Grid item xs={12}>
            <Table
              sx={{ border: '1px solid #E9ECF5', fontWeight: '500' }}
              size='small'
              aria-label='a dense table'
            >
              <TableHead>
                <TableRow height='56px'>
                  <TableCell
                    align='left'
                    colSpan={9}
                    style={{ height: '59px' }}
                  >
                    <Typography
                      variant='h3'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {i18n.t('course.classList')}
                      <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Box sx={{ width: '300px' }}>
                          <AutoSearch
                            placeholder={i18n.t('others.search')}
                            search={searchTerm}
                            setSearch={setSearchTerm}
                            handler={() => setSearchTerm('')}
                            sx={{ width: '300px' }}
                          />
                        </Box>

                        <Box
                          sx={{
                            width: '100px',
                            marginLeft: '20px',
                          }}
                        >
                          <Select
                            value={pageSize}
                            onChange={handlePageSizeChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Page size' }}
                            sx={{
                              width: '80px',
                              height: '40px',
                            }}
                          >
                            {availablePageSizes.map((size) => (
                              <MenuItem key={size} value={size}>
                                {size}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '120px',
                            height: '40px',
                            border: '1px solid #E9ECF5',
                            marginRight: '20px',
                          }}
                        >
                          <FilterList
                            cardStyle={{
                              position: 'absolute',
                              zIndex: '1',
                              top: '140px',
                              right: '323px',
                            }}
                            filters={filters}
                            setCanFilter={setCanFilter}
                            canFilter={canFilter}
                          />
                          {i18n.t('others.filter')}
                        </Box>
                        <Box>
                          {/* <Button
                            variant="outlined"
                            sx={{
                              height: "35px",
                              width: "80px",
                              color: "#FFC107",
                              backgroundColor: "#FFFFFF",
                              borderColor: "#FFC107",

                              "&:hover": {
                                color: "#FFC107",
                                backgroundColor: "#FFFFFF",
                                borderColor: "#FFC107",
                                // Add other hover styles as needed
                              },
                            }}
                            onClick={newItem}
                          >
                            <AddBoxRounded />
                            {i18n.t("button.new")}
                          </Button> */}
                        </Box>
                      </Box>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: '#F8FAFD',
                    height: '56px',
                  }}
                >
                  <TableCell sx={{ color: '#939a9f' }}>
                    {i18n.t('others.course')}
                  </TableCell>
                  <TableCell sx={{ color: '#939a9f' }}>
                    {i18n.t('others.disciplines')}
                  </TableCell>
                  <TableCell sx={{ color: '#939a9f' }}>
                    {i18n.t('others.year')}
                  </TableCell>
                  <TableCell sx={{ color: '#939a9f' }}>
                    {i18n.t('others.semester')}
                  </TableCell>
                  <TableCell sx={{ color: '#939a9f' }}>
                    {i18n.t('others.groups')}
                  </TableCell>
                  <TableCell sx={{ color: '#939a9f' }} align='center'>
                    {i18n.t('others.nºStudents')}
                  </TableCell>
                  <TableCell
                    sx={{ color: '#939a9f', textTransform: 'capitalize' }}
                    align='center'
                  >
                    {i18n.t('others.options')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((data, index) => (
                  <TableRow
                    key={data.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {data?.course?.name}
                    </TableCell>
                    <TableCell>{data.subject?.name}</TableCell>
                    <TableCell>{data.year}</TableCell>
                    <TableCell>{data.semester}</TableCell>
                    <TableCell align='center'>{data.numberOfGroups}</TableCell>
                    <TableCell align='center'>
                      {data.numberOfStudents}
                    </TableCell>
                    <TableCell align='center'>
                      {
                        <ActionButtons
                          id={data.id}
                          handleVisualize={analyzeItem}
                          handleEdit={editItem}
                          handleDeleteItem={() => handleClickOpen(data.id)}
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <FallBackCard data={IncomingData} isLoading={isLoading} />
          </Grid>
        )}
      </Grid>

      {!isLoading && IncomingData?.length ? (
        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
          <CustomPagination
            setPage={setCurrentPage}
            totalPages={totalPages}
            page={currentPage}
          />
        </Grid>
      ) : null}

      {deleteItemId && (
        <AlertDialogForConfirm
          id={deleteItemId}
          open={open}
          handleClose={handleClose}
          handleDeleteItem={deleteItem}
          message={message}
          isSuccessful={success}
          isLoading={loading}
        />
      )}
    </>
  );
}
